import React from "react";
import WelcomePage from "./components/WelcomePage";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";
import CookieConsent from "./components/CookieConsent";

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <CookieConsent/>
      <Header />
      <WelcomePage />
    </div>
  );
}

export default App;
